export const initSubstitutionCategoryHandler = () => {
  const input = document.querySelector(".js-substitution-category") as HTMLInputElement | null;
  const inputSubcategory = document.querySelector(".js-substitution-subcategory") as HTMLInputElement | null;
  const inputName = document.querySelector(".js-name") as HTMLInputElement | null;

  if (input && inputSubcategory && inputName) {
    input.addEventListener("change", (event) => {
      const target = event.target as HTMLInputElement;
      inputSubcategory.value = "";

      const targetClass = target.value == "Gas" ? "js-electricity" : "js-gas";

      inputSubcategory.querySelectorAll("option").forEach((option) => {
        option.hidden = false;
      });
      inputSubcategory.querySelectorAll("option." + targetClass).forEach((option) => {
        (option as HTMLOptionElement).hidden = true;
      });
    });

    inputSubcategory.addEventListener("change", (event) => {
      const target = event.target as HTMLInputElement;
      inputName.value = "Substitution durch " + target.value;
    });
  }
};
