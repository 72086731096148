// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";

import "chartkick/highcharts";
const Highcharts = require("highcharts")
import $ from "jquery";
import "bootstrap";
import "popper.js";
import bsCustomFileInput from "bs-custom-file-input";

import "trix"
import "@rails/actiontext"

import Sortable from "sortablejs";

import { initCalculator } from "./solar-calculator";
import { initLifespanHandler } from "./lifespan-input-handler"
import { initFilterMeasurementsCheckboxHandler } from "./filter-measurements"
import { initSubstitutionCategoryHandler } from "./substitution-category-handler"

window.$ = $;


window.Highcharts = Highcharts

Rails.start();
Turbolinks.start();

window.initCalculator = initCalculator

function updateFirst(event) {
  const p = document.querySelector("#color_text_field_1");
  if (p) {
    p.value = event.target.value;
  }
}

function updateColorPicker(event) {
  const colorPicker = document.querySelector("#color_1");
  if (colorPicker) {
    colorPicker.value = event.target.value;
  }
}

function updateFirst_2(event) {
  const p2 = document.querySelector("#color_text_field_2");
  if (p2) {
    p2.value = event.target.value;
  }
}

function updateColorPicker_2(event) {
  const colorPicker = document.querySelector("#color_2");
  if (colorPicker) {
    colorPicker.value = event.target.value;
  }
}

function updateFirst_3(event) {
  const p3 = document.querySelector("#color_text_field_3");
  if (p3) {
    p3.value = event.target.value;
  }
}

function updateColorPicker_3(event) {
  const colorPicker = document.querySelector("#color_3");
  if (colorPicker) {
    colorPicker.value = event.target.value;
  }
}

const updateYearlyCost = () => {
  var status1container = document.querySelectorAll(".js-order-board");
  if (status1container.length > 0) {
    $.each(status1container, function (container) {
      let sum = 0;
      const h6s = $(status1container[container]).find("h6")
      $.each(h6s, function (i) {
        h6s[i].data
        sum += parseFloat(h6s[i].dataset["cost"])
      })
      $(".js-cost-" + status1container[container].dataset["year"]).text("Geplante Kosten: " + Math.round(sum).toLocaleString("de") + " €")
    });
  }
}

document.addEventListener("turbolinks:load", function () {
  initLifespanHandler()
  initFilterMeasurementsCheckboxHandler()
  initSubstitutionCategoryHandler()

  $(".js-add-year").on("click", function (e) {
    e.preventDefault();
    let $container = $(this).prev()
    const lastGroup = $container.find(".js-input-group").last()
    const newGroup = lastGroup.clone()
    const newYear = parseInt(lastGroup.data("year") - 1)
    newGroup.attr("data-year", newYear)
    newGroup.find("span").html(newYear)
    const newName = newGroup.find("input").attr("name").replace((newYear + 1).toString(), newYear.toString())
    newGroup.find("input").attr("name", newName).val("")
    $container.append(newGroup)

    return false
  })

  var status1container = document.querySelectorAll(".js-order-board");
  if (status1container.length > 0) {
    $.each(status1container, function (container) {
      Sortable.create(status1container[container], {
        group: "status",
        animation: 250,
        // handle: ".my-handle",
        onChange: function (/**Event*/ evt) {
          // console.log("on change");
          // console.log("changed position in wizard fragen", evt.newIndex);
          // most likely why this event is used is to get the dragging element's current index
          // same properties as onEnd
        },
        onUpdate: function (/**Event*/ evt) {
          // console.log("on update");
          //setInteactive
          // updateQuestionsOrder();
          // same properties as onEnd
        },
        onAdd: function (/**Event*/ evt) {
          // let $parent = $(evt.item).parent()
          // console.log($parent);
          // console.log($(evt.item).parent());
          // console.log($(evt.item).find(".js-question-visible"), "need to check");
          // updateQuestionsOrder();
          // same properties as onEnd
          // var orderId = $(evt.item).data("order-id")
          // $(".js-loader-" + orderId).css("display", "block")
          updateYearlyCost()
        },
      });
      // let sum = 0;
      // const h6s = $(status1container[container]).find("h6")
      // $.each(h6s, function(i) {
      //   h6s[i].data
      //   sum += parseFloat(h6s[i].dataset["cost"])
      // })
      // $(".js-cost-" + status1container[container].dataset["year"]).text("Geplante Kosten: " + Math.round(sum) + " €")
    });
    updateYearlyCost()

  }



  bsCustomFileInput.init();
  let slide = 0;
  const slides = 5.0;
  $(".js-continue").on("click", function () {
    const nextSlide = slide + 1;
    // console.log(nextSlide);
    if (slide == 1) {
      let error = false;
      $(".js-f1").each(function (e) {
        if ($(this).val() == "") {
          $(this).addClass("is-invalid");
          error = true;
        } else {
          $(this).removeClass("is-invalid");
          $(this).addClass("is-valid");
        }
      });
      if (error) {
        return;
      }
    }
    if (slide == 2) {
      let error = false;
      // $(".js-f2").each(function (e) {
      //   if ($(this).val() == "") {
      //     $(this).addClass("is-invalid");
      //     error = true;
      //   } else {
      //     $(this).removeClass("is-invalid");
      //     $(this).addClass("is-valid");
      //   }
      // });
      if (error) {
        return;
      }
    }
    if (slide == 3) {
      let error2 = false;
      $(".js-f3").each(function (e) {
        if ($(this).val() == "") {
          $(this).addClass("is-invalid");
          error2 = true;
        } else {
          $(this).removeClass("is-invalid");
          $(this).addClass("is-valid");
        }
      });
      if (error2) {
        console.log("stopping here");
        return;
      }
    }
    if (slide == 4) {
      let error = false;
      if ($(".js-f4").val().length < 5) {
        $(".js-f4").addClass("is-invalid");
        error = true;
      } else {
        $(".js-f4").removeClass("is-invalid");
        $(".js-f4").addClass("is-valid");
      }

      if (error) {
        return;
      }
    }
    $(".js-q" + (nextSlide - 1)).fadeOut(function () {
      $(".js-q" + nextSlide).fadeIn();
      let newVal = (100 / slides) * nextSlide;
      $(".js-progress-bar").width(newVal + "%");
      slide = nextSlide;
      if (slide == 5) {
        $(".js-progress-container").hide();
        setTimeout(function () {
          $(".js-q" + slide).fadeOut(function () {
            $(".js-q" + (slide + 1)).fadeIn();
          });
        }, 2000);
      }
    });
  });

  $(".js-back").on("click", function () {
    const nextSlide = slide > 0 ? slide - 1 : 0;
    $(".js-q" + (nextSlide + 1)).fadeOut(function () {
      $(".js-q" + nextSlide).fadeIn();
      let newVal = (100 / slides) * nextSlide;
      $(".js-progress-bar").width(newVal + "%");
      slide = nextSlide;
    });
  });

  $("#js-cta").click(function () {
    $("html, body").animate(
      {
        scrollTop: parseInt($("#js-wizard").offset().top),
      },
      500
    );
  });
  $('[data-toggle="tooltip"]').tooltip();

  $(".js-in-service").on("change", function () {
    $(".js-end-date").fadeOut();
  })

  $(".js-no-service").on("change", function () {
    $(".js-end-date").fadeIn();
  })

  $(".js-show-form").on("click", function () {
    $(".js-form").slideDown();
    $(this).hide();
  })

  $(".js-add-feature").on("click", function (event) {
    const time = new Date().getTime();
    const regexp = new RegExp($(this).data("id"), "g");
    $(".js-nested-features").append($(this).data("fields").replace(regexp, time));
    event.preventDefault();
  });

  $(".js-nested-features").on("click", ".js-remove-feature", function (event) {
    $(this).prev("input[type=hidden]").val("1");
    $(this).closest("fieldset").hide();
    event.preventDefault();
    event.stopPropagation();
  });

  const imgInp = document.getElementById('carbonwatch_item_image')
  if (imgInp) {
    imgInp.onchange = evt => {
      const [file] = imgInp.files
      if (file) {
        const imagePreview = document.getElementById('bla')
        if (imagePreview) {
          imagePreview.src = URL.createObjectURL(file)
          imagePreview.classList.remove("d-none")
        }
        const imagePlaceholder = document.getElementById('image_placeholder')
        if (imagePlaceholder) {
          imagePlaceholder.classList.add("d-none")
        }
      }
    }
  }
  let colorWell = document.querySelector("#color_1");
  if (colorWell) {
    colorWell.addEventListener("input", updateFirst, false);
  }
  const p = document.querySelector("#color_text_field_1");

  if (p) {
    p.addEventListener("change", updateColorPicker, false);
  }

  let colorWell2 = document.querySelector("#color_2");
  if (colorWell2) {
    colorWell2.addEventListener("input", updateFirst_2, false);
  }
  const p2 = document.querySelector("#color_text_field_2");
  if (p2) {
    p2.addEventListener("change", updateColorPicker_2, false);
  }

  let colorWell3 = document.querySelector("#color_3");
  if (colorWell3) {
    colorWell3.addEventListener("input", updateFirst_3, false);
  }
  const p3 = document.querySelector("#color_text_field_3");
  if (p3) {
    p3.addEventListener("change", updateColorPicker_3, false);
  }
});

document.addEventListener("turbolinks:before-cache", function () {
  $(".js-flash-message").remove();
  $('[data-toggle="tooltip"]').tooltip("hide");
});
