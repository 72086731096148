export const initLifespanHandler = () => {
  const input = document.querySelector(".js-lifespan") as HTMLInputElement | null;

  if (input) {
    input.addEventListener("blur", (event) => {
      const target = event.target as HTMLInputElement;
      const lifespan = parseInt(target.value);
      const container = document.querySelector(".js-lifespan-years-container") as HTMLElement;
      container.classList.remove("d-none");
      const ecContainer = document.querySelector(".js-lifespan-ec-container") as HTMLElement;
      const e2Container = document.querySelector(".js-lifespan-e2-container") as HTMLElement;
      const oilContainer = document.querySelector(".js-lifespan-heating-oil-container") as HTMLElement;
      ecContainer.innerHTML = "";
      e2Container.innerHTML = "";
      oilContainer.innerHTML = "";
      for (let index = 0; index < lifespan; index++) {
        const input = document.createElement("input");
        input.classList.add("form-control");
        input.classList.add("mb-3");
        input.style.maxWidth = "400px";
        input.placeholder = "Jahr " + (index + 1);
        input.name = "savings_per_year_ec[]";
        ecContainer.appendChild(input);

        const e2Input = document.createElement("input");
        e2Input.classList.add("form-control");
        e2Input.classList.add("mb-3");
        e2Input.style.maxWidth = "400px";
        e2Input.placeholder = "Jahr " + (index + 1);
        e2Input.name = "savings_per_year_e2[]";
        e2Container.appendChild(e2Input);

        const oilInput = document.createElement("input");
        oilInput.classList.add("form-control");
        oilInput.classList.add("mb-3");
        oilInput.style.maxWidth = "400px";
        oilInput.placeholder = "Jahr " + (index + 1);
        oilInput.name = "savings_per_year_heating_oil[]";
        oilContainer.appendChild(oilInput);
      }
    });
  }
};
