interface UserGeolocation {
  lat: number;
  lng: number;
}

const defaultCenter: UserGeolocation = {
  lat: 51.1657,
  lng: 10.4515
}

const loadDefaultMap = () => {
  new google.maps.Map(
    document.getElementById("map") as HTMLElement,
    {
      center: defaultCenter,
      zoom: 6,
      mapTypeId: 'roadmap',
      disableDefaultUI: true,
      tilt: 0
    }
  );
}

function loadDrawableMap(location: UserGeolocation, zoom: number): void {
  const map = new google.maps.Map(
    document.getElementById("map") as HTMLElement,
    {
      center: location,
      zoom,
      mapTypeId: 'satellite',
      disableDefaultUI: true,
      tilt: 0
    }
  );

  const drawingManager = new google.maps.drawing.DrawingManager({
    drawingMode: google.maps.drawing.OverlayType.POLYGON,
    drawingControl: true,
    drawingControlOptions: {
      position: google.maps.ControlPosition.TOP_CENTER,
      drawingModes: [
        google.maps.drawing.OverlayType.POLYGON,
      ],
    },
    polygonOptions: {
      editable: true,
      clickable: true,
      draggable: true,
      strokeColor: "red",
      fillColor: "red"
    },
  });

  drawingManager.setMap(map);

  google.maps.event.addListener(drawingManager, 'polygoncomplete', function(polygon) {
    const result = google.maps.geometry.spherical.computeArea(polygon.getPath())
    const output = document.querySelector(".js-qm") as HTMLElement;
    output.textContent = Math.round(result).toString()
    const disabledArea = document.querySelector(".js-disabled-area-2") as HTMLElement;
    disabledArea.classList.remove("step-disabled")
  });
}

export const initCalculator = () => {
  const mapContainer = document.getElementById("map")

  if (mapContainer) {
    loadDefaultMap()

    const input = document.getElementById("pac-input") as HTMLInputElement;
    const options = {
      componentRestrictions: { country: "de" },
      fields: ["address_components", "geometry", "name"],
      strictBounds: false,
    };
  
    const autocomplete = new google.maps.places.Autocomplete(input, options);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
  
      const lat = place.geometry?.location?.lat() || defaultCenter.lat
      const lng = place.geometry?.location?.lng() || defaultCenter.lng
  
      loadDrawableMap({lat, lng}, 20)

      const disabledArea = document.querySelector(".js-disabled-area-1") as HTMLElement;
      disabledArea.classList.remove("step-disabled")
    })
  }
}

// window.initCalculator = initCalculator;
