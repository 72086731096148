export const initFilterMeasurementsCheckboxHandler = () => {
  const checkboxes = document.querySelectorAll(".js-category-check") as NodeListOf<HTMLInputElement>

  checkboxes.forEach((checkbox) => {
    checkbox.addEventListener("change", (event) => {
      const target = event.target as HTMLInputElement;
      const index = target.dataset.index as String;

      const childCheckboxes = document.querySelectorAll(".js-child-check-" + index) as NodeListOf<HTMLInputElement>

      childCheckboxes.forEach(checkbox => {
        checkbox.checked = target.checked
      })
    })
  })

  const childCheckboxes = document.querySelectorAll(".js-child-check") as NodeListOf<HTMLInputElement>

  childCheckboxes.forEach((checkbox) => {
    checkbox.addEventListener("change", (event) => {
      const target = event.target as HTMLInputElement;
      const index = target.dataset.index as String;

      const parentCheckbox = document.querySelector(".js-category-check-" + index) as HTMLInputElement | null

      if (parentCheckbox && target.checked) {
        parentCheckbox.checked = true
      }
    })
  })
}